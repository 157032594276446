import { render, staticRenderFns } from "./changephone.vue?vue&type=template&id=8226271e&scoped=true&"
import script from "./changephone.vue?vue&type=script&lang=js&"
export * from "./changephone.vue?vue&type=script&lang=js&"
import style0 from "./changephone.vue?vue&type=style&index=0&id=8226271e&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8226271e",
  null
  
)

export default component.exports