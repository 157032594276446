<!-- 注册 -->
<template>
	<div :class="themeClass" v-loading="pageLoading">
		<div style="width: 100%;" class="border-bottom-F2F2F2">
			<div class="login-t pointer">
				<router-link to="/">
					<img :src="logoUrl" style="height: 54px;" />
				</router-link>
			</div>
		</div>

		<div class="reg-box flex-column-start-center">
			<div v-if="active==1">
				<div class="reg-form-box">
					<el-form :model="ruleForm" :rules="rules" line-height="20" ref="ruleForm" label-width="100px" id="selectForm">
						<el-form-item label="手机号码:" prop="phoneNumber" >
							<el-input :placeholder="getUserInfo.phone" v-model.number="ruleForm.phoneNumber" maxlength="11" autocomplete="off" disabled></el-input>
						</el-form-item>
						<el-form-item label="验证码:" prop="VerCode" style="position: relative;">
							<div v-show="innerVisibles" style="position: absolute;bottom: 0px;z-index: 999; padding: 3px; background-color: #fff; left: 0;">
								<i  class="el-icon-circle-close" style="position: absolute;right: -20px;top: -20px;color: #0173FE;font-size:26px;" @click="closeYanzheng"></i>
								<Graphic @messageData = 'messageData'></Graphic>
							</div>
							<div class="flex-row-space-between-center">
								<el-input style="width: 70%;" placeholder="请输入短信验证码" v-model="ruleForm.VerCode">
								</el-input>
								<div class="pointer verification-code-button flex-row-center-center background-color-theme font-size13" v-if="subStatus"
								 @click="countDown">{{getYZ}}</div>
								<div class=" verification-code-button flex-row-center-center background-color-theme font-size13" v-if="!subStatus">{{resend}}{{getYZ}}{{miao}}</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="reg-button pointer flex-row-center-center font-size14 font-weight700 background-color-theme" @click="toRegister">下一步</div>
			</div>
			<div v-if="active==2">
				<div class="flex-row-center-center success-box">
					<div class="img-box">
						<el-image :src="require('@/assets/imgs/u372.png')"></el-image>
					</div>
					<div class="flex-column-center-start inner-text-box margin-l-19">
						<div class="font-size28 font-weight700 font-color-333">修改成功</div>
						<div class="font-size13 font-color-333">{{miaoTZ}}秒自动跳转</div>
					</div>
				</div>
			<!-- 	<div class="reg-button pointer flex-row-center-center font-size14 font-weight700 background-color-theme" @click="toInformation">完善资料</div> -->
			</div>
			<agreementDialog :iContents ='iContents' ref="areDialog" :list="zhuceData" @handleClick="handleClick"></agreementDialog>
		</div>
		

		<Footer></Footer>
	</div>
</template>

<script>
	import agreementDialog from '@/components/agreementDialog';
	import Graphic from '@/components/Graphic';
	import {
		mapGetters,
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				pageLoading: false,
				iContent: '',
				iContents: '',
				fTitle: "",
				radio: false, //协议的按钮
				active: 1,
				getYZ: "发送验证码",
				resend: '',
				innerVisibles:false,
				yanzhengA:false, //判断图形验证码出现
				noticeShow: false, //判断弹框弹出
				logoUrl: '',
				miao: '',
				msg:false,
				miaoTZ: 60,
				ruleForm: {
					phoneNumber: '',
					VerCode: '',
					password: "",
					pwdConfirm: '',
					radio: false, //协议的按钮
				},
				zhuceData: [], //协议列表
				subStatus: true,
				rules: {
					phoneNumber: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请输入正确的手机号码'));
							} else {
								let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
								if (phoneNumberRul.test(value)) {
									callback();
								} else {
									callback(new Error('请输入正确的手机号码'));
								}
							}
						},
						trigger: 'blur'
					}],
					VerCode: [{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}]
				}
			}
		},
		mounted() {
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		},
		created() {
		},
		components: {
			// RichText,
			agreementDialog,
			Graphic,
		},
		computed: {
			...mapGetters(["getThemeName", "getFAppTypeID","getUserInfo","getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			...mapActions(["setUser", "setShowLogin"]),
			messageData(a) {
					if(a){
						var _this = this;
						setTimeout(function(){
							_this.innerVisibles = false;
							_this.msg = a
							_this.countDown();
							console.log('子传递值，已经变成true')
						}, 500);
					}
			 },
			toHome() {
				this.$router.push("/home")
			},
			// 关闭验证码
			closeYanzheng(){
				this.innerVisibles = false
			},
			// 判断弹框关闭或者开启的两个方法
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			
			
			handleClick(a){
				this.iContents = a;
			},
			//展示协议
			showAgreeDia(a) {
				console.log(a)
				this.noticeShow = true;
				this.iContents = a;
				this.$refs.areDialog.show();
			},
			//获取验证码
			countDown() {
				this.ruleForm.phoneNumber = this.getUserInfo.phone
				var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if (this.ruleForm.phoneNumber == '' || this.ruleForm.phoneNumber == undefined) {
					return this.tipsInfo('请输入手机号');
				}
				if (!reg_tel.test(this.ruleForm.phoneNumber)) {
					return;
				}
							if(this.msg == false){
								this.innerVisibles = true;
								return;
							}
							// 测试接口 TODO 后续需要更换
							this.ApiRequestPostNOMess('/api/mall/sys/profile/send-sms-old-phone', {
								phone: this.ruleForm.phoneNumber,
							}).then(res => {
								// this.tipsInfo('验证码已发送')
								this.getYZ = 60;
								this.miao = "秒）";
								this.resend = '重发验证（';
								this.subStatus = false;
								let time = setInterval(() => {
									this.getYZ--;
									if (this.getYZ == 0) {
										clearInterval(time);
										this.miao = "";
										this.resend = '';
										this.getYZ = "发送验证码";
										this.subStatus = true;
										this.msg = false
									}
								}, 1000);
							}, error => {
								
							});

				
				

			},
			//立即注册
			toRegister() {
				
				// console.log("this.fAppTypeID",this.fAppTypeID);
				let appTypeID = this.fAppTypeID
				this.$refs.ruleForm.validate((valid) => {
					if (!valid) {
						return
					}
					this.ApiRequestPostNOMess('api/mall/sys/profile/check-old-phone', {
						token: this.ruleForm.VerCode,
					}).then(res => {
						if(res.obj.status == true){
							this.$router.push({
								path: '/changephoneitem', query: { code: res.obj.token }})
						}
						
					}, error => {
					
					});
					
					
					
					
					
					
					

				});
			},
			//跳转倒计时
			countDownTZ() {
				this.miaoTZ = 5;
				let time = setInterval(() => {
					this.miaoTZ--;
					if (this.miaoTZ == 0) {
						clearInterval(time);
						this.toInformation();
					}
				}, 1000);
			},
			getUserInfo() {
				this.ApiRequestPostNOMess(
					"/api/mall/ebcustomer/baseinfo/get-current-customer-user-info"
				).then(
					(result) => {
						this.$store.commit("setUserInfo", result.obj);
						this.setUser(result.obj);
						sessionStorage.setItem("setUserInfo", JSON.stringify(result.obj));
					},
					(rej) => {}
				);
			},
			//完善资料跳转
			toInformation() {
			
				this.$router.push({
					path: '/PersonalContent/Allperfect', params: { from: "1" }})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
	
	.form-content {
		position: relative;
		z-index: 8;
		width: 90%;
		margin: 0 auto;
	}
	#setform > div{
		line-height: 20px !important;
	}
	
	.lh21 {
		line-height: 21px;
	}

	.agreement-box {
		width: 54%;
		margin: 10px auto;
		color: #999999;
	}

	.agreement-box {
		// width: 22%;
		// margin: 12px auto;
		display: flex;
	}

	.agreement-box ::v-deep .el-checkbox__label {
		color: #999999;
		font-size: 12px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.login-t {
		font-weight: 700;
		font-size: 18px;
		line-height: 110px;
		height: 80px;
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;
	}

	.reg-box {
		// width: 1100px;
		padding: 55px 0 56px 0
	}

	.reg-inner-box {
		padding: 10px 0 0 27px;
		width: 390px;
	}

	.reg-form-box {
		margin-top: 50px;
		width: 417px;
	}

	.reg-button {
		user-select: none;
		width: 300px;
		height: 40px;
		border-radius: 3px;
		margin: 85px auto 0;
	}

	// .img-box {
	// 	width: 100px;
	// 	height: 100px;
	// }

	.inner-text-box {
		height: 80px;
	}

	.success-box {
		margin-top: 80px;
		width: 417px;
	}

	.verification-code-button {
		width: 130px;
		height: 30px;
		border-radius: 2px;
		user-select: none;
	}

	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}

	::v-deep .el-checkbox__label {
		color: #999999 !important;
		font-size: 12px !important;
	}


	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		font-size: 14px;
		// color: #999999;
	}

	::v-deep .el-step__title.is-success {
		font-weight: 400;
		font-size: 14px;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */
</style>
